import { restApi } from "../../services/api";
import { createBasicRepository as createRepository } from "elentari";
import { query, paginate } from "../../lib/feathers";

const usersRepository = createRepository({
  path: "users",
  restApi,
  queryTransform: {
    displayName: query.ilike("displayName"),
    email: query.ilike("email"),
    telephoneNumber: query.ilikeSplit("telephoneNumber"),
    ...paginate,
  },
});

const _list = usersRepository.list;
usersRepository.list = async data => {
  if (data?.query?.ok) {
    delete data.query.ok;
  }
  return _list(data);
};

export default usersRepository;
