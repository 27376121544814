import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Breadcrumb from "./Breadcrumb";
import Grid from "@material-ui/core/Grid";
import UserAvatar from "./UserAvatar";
import * as R from "ramda";
import blueGrey from "@material-ui/core/colors/blueGrey";
import logo from "../images/g10-white-logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: 0,
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  navBarRoot: {
    backgroundColor: theme.palette.primary,
    color: blueGrey["900"],
  },
  content: {
    width: "100%",
    overflow: "auto",
    position: "relative",
    flexGrow: 1,
    backgroundColor: theme.palette.grey["200"],
    padding: 24,
    height: "calc(100% - 56px)",
    marginTop: 56,
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 110px)",
      marginTop: 64,
    },
  },
  toolsLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.palette.common.white,
  },
  toolsRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "20px !important",
  },
  logo: {
    maxWidth: 96,
    marginRight: 24,
  },
  whiteLetters: {
    color: theme.palette.common.white,
    borderRadius: 3,
  },
}));

const getReadable = menuItem => {
  if (menuItem.pathname) {
    const paths = menuItem.pathname.split("/");
    if (paths.length === 2) {
      return { [paths[1]]: menuItem.label };
    }
    if (paths.length === 3) {
      return { [paths[2]]: menuItem.label };
    }
  }
  return {};
};

const getDafaultsReadableMap = menuItems => {
  const readables = menuItems.map(menuItem => {
    if (menuItem.group) {
      const readable = getReadable(menuItem);
      const subs = menuItem.items.map(subItem => getReadable(subItem));
      return [readable, ...subs];
    } else {
      return getReadable(menuItem);
    }
  });
  return R.mergeAll(R.flatten(readables));
};

const AppWrap = ({
  children,
  menuItems,
  onLogout,
  pathReadableMap,
  userAvatarProps,
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <AppBar position="absolute">
          <Toolbar disableGutters classes={{ root: classes.navBarRoot }}>
            <Grid container>
              <Grid item xs className={classNames(classes.toolsLeft)}>
                <img alt="logo G10" src={logo} className={classes.logo} />
                <Breadcrumb
                  history={history}
                  pathReadableMap={R.mergeRight(
                    getDafaultsReadableMap(menuItems),
                    pathReadableMap
                  )}
                />
              </Grid>
              <Grid item className={classNames(classes.toolsRight)}>
                <UserAvatar onLogout={onLogout} {...userAvatarProps} />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <main className={classNames(classes.content)}>
          <div>{children}</div>
        </main>
      </div>
    </div>
  );
};

export default AppWrap;
