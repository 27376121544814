import { createBasicRepository as createRepository } from "elentari";
import { restApi } from "../../services/api";
import { paginate } from "../../lib/feathers";

const userFieldRepository = createRepository({
  path: "user-field",
  restApi,
  queryTransform: {
    ...paginate,
  },
});

export default userFieldRepository;
