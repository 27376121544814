import React, { useContext } from "react";
import * as R from "ramda";
import ListTile from "./ListTile";
import { AuthContext } from "elentari";

const UserAvatar = props => {
  const context = useContext(AuthContext);

  const logout = () => {
    context.logout && context.logout();
    props.onLogout && props.onLogout();
  };

  const username = R.path(["user", "name"], context);
  return (
    <ListTile
      {...props}
      onActionClick={props.onActionClick || logout}
      name={username}
    />
  );
};

export default UserAvatar;
