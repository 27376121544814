import React, { useEffect, useState } from "react";
import { PureAutocomplete } from "elentari";
import { CancelToken } from "apisauce";

const Autocomplete = ({
  input,
  meta,
  repository,
  labelOption,
  loadOptions,
  getOneRepository,
  labelFormat,
  ...props
}) => {
  const [value, setValue] = useState();

  useEffect(() => {
    const source = CancelToken.source();
    const fetch = async () => {
      if (input.value !== null) {
        setValue({ label: input.value });
      } else {
        setValue(null);
      }
    };
    fetch();
    return () => source.cancel();
  }, [
    input.value,
    labelOption,
    repository,
    getOneRepository,
    labelFormat,
    props.allowDeletedAt,
    props.isMulti,
  ]);

  const onChange = item => {
    setValue(item);
    input.onChange(
      item
        ? Array.isArray(item)
          ? item.map(item => item.label)
          : item.label
        : undefined
    );
  };

  const touched = meta.touched || props.showError;
  return (
    <PureAutocomplete
      {...props}
      meta={meta}
      error={meta.error && touched}
      textFieldProps={{
        onBlur: input.onBlur,
        helperText: touched && meta.error,
        error: meta.error && touched,
      }}
      helperText={touched && meta.error}
      InputLabelProps={{ shrink: true }}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      loadingMessage={() => "Carregando..."}
    />
  );
};

export default Autocomplete;
