import React from "react";
import Grid from "@material-ui/core/Grid";
import { Field } from "react-final-form";
import { CrudForm, TextField, useEntityManager } from "elentari";
import usersRepository from "./usersRepository";
import { useAbility } from ".";
import { Email } from "../../Components";
import * as yup from "yup";
import yupValidation from "../../lib/yupValidation";
import formatString from "format-string-by-pattern";

const userSchema = yup.object().shape({
  displayName: yup.string().required("Obrigatório"),
  email: yup
    .string()
    .email("Email Inválido")
    .required("Obrigatório"),
  telephoneNumber: yup.string().required("Obrigatório"),
  physicalDeliveryOfficeName: yup.string().required("Obrigatório"),
  initials: yup.string().required("Obrigatório"),
  title: yup.string().required("Obrigatório"),
  department: yup.string().required("Obrigatório"),
  company: yup.string().required("Obrigatório"),
  city: yup
    .string()
    .nullable()
    .required("Obrigatório"),
  state: yup
    .string()
    .nullable()
    .required("Obrigatório"),
});

export const parseTelefone = anyString => {
  if (anyString) {
    const onlyNumbers = anyString.replace(/[^\d]/g, "");
    if (onlyNumbers.length < 11) {
      return formatString("(00) 0000-0000")(onlyNumbers);
    }
    if (onlyNumbers.length < 12) {
      return formatString("(00) 00000-0000")(onlyNumbers);
    }
    return formatString("+00 (00) 00000-0000")(onlyNumbers);
  }
};

const validate = yupValidation(userSchema);

const UserForm = props => {
  const abilities = useAbility();

  const entityManager = useEntityManager({
    repository: usersRepository,
  });
  const cannotUpdate =
    abilities.cannot("update", "users") && !entityManager.isNew;

  const handleSubmit = async data => {
    if (!cannotUpdate) {
      return entityManager.onSubmit({
        displayName: data.displayName || null,
        email: data.email || null,
        telephoneNumber: data.telephoneNumber || null,
        mobile: data.mobile || null,
        homePhone: data.homePhone || null,
        ipPhone: data.ipPhone || null,
        physicalDeliveryOfficeName: data.physicalDeliveryOfficeName || null,
        initials: data.initials || null,
        title: data.title || null,
        department: data.department || null,
        company: data.company || null,
        city: data.city || null,
        state: data.state || null,
      });
    }
  };

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        disableSubmit={cannotUpdate}
        onSubmit={handleSubmit}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              name="displayName"
              label="Nome"
              component={TextField}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Email />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="telephoneNumber"
              parse={parseTelefone}
              label="Telefone Principal"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="mobile"
              parse={parseTelefone}
              label="Celular"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="homePhone"
              parse={parseTelefone}
              label="Telefone fixo"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="ipPhone"
              label="Ramal"
              component={TextField}
            />
          </Grid>
          <Grid item sm={2} xs={12}>
            <Field
              fullWidth
              name="physicalDeliveryOfficeName"
              label="Empresa"
              component={TextField}
            />
          </Grid>
          <Grid item sm={1} xs={12}>
            <Field
              fullWidth
              name="initials"
              label="Sigla"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field fullWidth name="title" label="Cargo" component={TextField} />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="department"
              label="Departamento"
              component={TextField}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              name="company"
              label="Unidade de Negócio"
              component={TextField}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field fullWidth name="city" label="Cidade" component={TextField} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              fullWidth
              name="state"
              label="Estado"
              component={TextField}
            />
          </Grid>
        </Grid>
      </CrudForm>
    </>
  );
};

export default UserForm;
