import config from "../config";
import { create } from "apisauce";

export const restApi = create({
  baseURL: config.baseURL,
});

const getApi = () => {
  return {
    setToken: token => restApi.setHeader("Authorization", `Bearer ${token}`),
    ping: () => restApi.get(`/users`),
    login: credentials =>
      restApi.post("/authentication", {
        strategy: "local",
        ...credentials,
      }),
    logout: () => restApi.delete("/authentication"),
  };
};

export default getApi();
