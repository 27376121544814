import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import recoveryAccessRepository from "./recoveryAccessRepository";
import { Link, useHistory, useLocation } from "react-router-dom";
import blueGrey from "@material-ui/core/colors/blueGrey";
import {
  AppBar,
  CircularProgress,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import logo from "../../images/g10-white-logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: 0,
    backgroundColor: theme.palette.grey["200"],
    zIndex: 1,
    overflow: "hidden",
  },
  navBarRoot: {
    backgroundColor: theme.palette.primary,
    color: blueGrey["900"],
  },
  content: {
    width: "100%",
    overflow: "auto",
    position: "relative",
    flexGrow: 1,
    padding: 24,
    height: "calc(100% - 56px)",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100% - 56px)",
    },
  },
  toolsLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 24,
    color: theme.palette.common.white,
  },
  logo: {
    maxWidth: 96,
    marginRight: 24,
  },
  breadcrumb: {
    display: "flex",
    flexDirection: "row",
    marginButton: "15px",
    alignItems: "center",
  },
  breadcrumbTitle: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    color: theme.palette.common.grey,
    cursor: "default !important",
  },
}));

const RecoveryAccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState("idle");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const state = searchParams.get("state");
    if (code && state) {
      setState("loading");
      recoveryAccessRepository.create({ code, state }).then(res => {
        if (res.ok) {
          setState("success");
        } else {
          setState("error");
        }
        setTimeout(() => {
          history.push("/");
        }, 4000);
      });
    }
  }, [history, location]);

  const renderContent = () => {
    switch (state) {
      case "loading":
        return (
          <Typography variant="h4" align="center">
            <CircularProgress /> <br />
            Aguarde, seu token esta sendo gerado.
          </Typography>
        );
      case "success":
        return (
          <Typography variant="h4" align="center">
            Sucesso. Acesso renovado.
          </Typography>
        );
      case "error":
        return (
          <Typography variant="h4" align="center">
            Falha sincronização.
          </Typography>
        );
      default:
        return (
          <Typography variant="h4" align="center">
            Acesso invalido.
          </Typography>
        );
    }
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar disableGutters classes={{ root: classes.navBarRoot }}>
          <Grid container>
            <Grid item xs className={classes.toolsLeft}>
              <img alt="logo G10" src={logo} className={classes.logo} />
              <div className={classes.breadcrumb}>
                <Typography className={classes.breadcrumbTitle}>
                  RENOVAR ACESSO
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.content}
      >
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            Clique
            <Link to={"/"}> aqui </Link>
            para acessar o sistema Contatos G10.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default RecoveryAccess;
