import React from "react";
import { withLogin } from "elentari";
import api from "../../services/api";
import { useLocation } from "react-router-dom";
import * as R from "ramda";
import logo from "../../images/g10-logo.png";
import { Grid } from "@material-ui/core";
import LoginForm from "./LoginForm";

const Login = withLogin(LoginForm);

export const handleLogin = ({ login, setToken = R.empty }) => async ({
  email,
  password,
}) => {
  if (!email || !password) return;
  const response = await login({
    email,
    password,
  });
  if (response.ok) {
    if (!response.data.user) {
      return { ok: false };
    }
    if (!response.data.accessToken) {
      return { ok: false, message: "Token não encontrado" };
    }
    setToken(response.data.accessToken);
    return {
      ok: true,
      token: response.data.accessToken,
      id: response.data.user.id,
      email: response.data.user.username,
      name: response.data.user.name,
      role: response.data.user.rolesInSystem,
    };
  }
  return { ok: false };
};
const CustomLogin = ({ history }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectPath = params.get("redirectPath");
  return (
    <Login
      logo={
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <img alt="logo" src={logo} style={{ marginBottom: 20 }} />
          </Grid>
        </Grid>
      }
      history={history}
      onSubmit={handleLogin(api)}
      requiredLabel="Obrigatório"
      usernameLabel="Usuário"
      submitLabel="Entrar"
      passwordLabel="Senha"
      recoverPasswordLabel=""
      redirectPath={redirectPath}
    />
  );
};

export default CustomLogin;
