import React, { useState } from "react";
import {
  List,
  Filter,
  useListRepository,
  useFilter,
  TextField,
} from "elentari";
import { Field } from "react-final-form";
import Grid from "@material-ui/core/Grid";
import usersRepository from "./usersRepository";
import { useAbility } from ".";
import { getListActions } from "../../lib/crudAuthorization";
import PhoneIcon from "@material-ui/icons/Phone";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Snackbar } from "elentari";
import { Tooltip } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import MailIcon from "@material-ui/icons/Mail";
import BusinessIcon from "@material-ui/icons/Business";
import WorkIcon from "@material-ui/icons/Work";
import RoomIcon from "@material-ui/icons/Room";
import { makeStyles } from "@material-ui/core/styles";
import UserFieldAutocomplete from "../user-field-autocomplete/UserFieldAutocomplete";

const useStyles = makeStyles(() => ({
  icon_header: {
    marginRight: "5px",
  },
}));

const UserFilter = () => {
  const filter = useFilter({});

  const detailedFilter = () => (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <Field
          name="telephoneNumber"
          label="Telefone"
          fullWidth
          component={TextField}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          name="company"
          field="company"
          label="Unidade de negócio"
          fullWidth
          component={UserFieldAutocomplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          name="physicalDeliveryOfficeName"
          field="physicalDeliveryOfficeName"
          label="Empresa"
          fullWidth
          component={UserFieldAutocomplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          name="department"
          field="department"
          label="Departamento"
          fullWidth
          component={UserFieldAutocomplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          name="title"
          field="title"
          label="Cargo"
          fullWidth
          component={UserFieldAutocomplete}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          name="state"
          field="state"
          label="Estado"
          fullWidth
          component={UserFieldAutocomplete}
        />
      </Grid>
    </Grid>
  );
  return (
    <Filter
      {...filter}
      detailedFilter={detailedFilter()}
      labels={{
        detailedFilter: "Busca avançada",
        find: "Buscar",
        clear: "Limpar",
        simpleFilter: "Busca simples",
      }}
    >
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Field
            name="displayName"
            label="Nome"
            fullWidth
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  );
};

const UsersList = ({ ...props }) => {
  const [message, setMessage] = useState("");

  const formatTelephone = item => {
    if (item.principal) {
      return (
        <Tooltip title="Telefone Principal">
          <ListItem>
            <ListItemText>{item.principal ? item.principal : "-"}</ListItemText>
          </ListItem>
        </Tooltip>
      );
    } else if (item.mobile) {
      return (
        <Tooltip title="Celular">
          <ListItem>
            <ListItemText>{item.mobile ? item.mobile : "-"}</ListItemText>
          </ListItem>
        </Tooltip>
      );
    } else if (item.homePhone) {
      return (
        <Tooltip title="Telefone Fixo">
          <ListItem>
            <ListItemText>{item.homePhone ? item.homePhone : "-"}</ListItemText>
          </ListItem>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Ramal">
          <ListItem>
            <ListItemText>{item.ipPhone ? item.ipPhone : "-"}</ListItemText>
          </ListItem>
        </Tooltip>
      );
    }
  };
  const classes = useStyles();
  const abilities = useAbility();
  const listOptions = {
    defaultOrder: "displayName",
    fields: {
      displayName: {
        label: (
          <>
            <PersonIcon className={classes.icon_header} /> Nome
          </>
        ),
        format: displayName => displayName || "Não cadastrado",
      },
      telephones: {
        label: (
          <>
            <PhoneIcon className={classes.icon_header} /> Telefone
          </>
        ),
        format: formatTelephone,
      },
      email: {
        label: (
          <>
            <MailIcon className={classes.icon_header} /> E-mail
          </>
        ),
        format: email => email || "Não cadastrado",
      },
      physicalDeliveryOfficeName: {
        label: (
          <>
            <BusinessIcon className={classes.icon_header} /> Empresa
          </>
        ),
        format: physicalDeliveryOfficeName =>
          physicalDeliveryOfficeName || "Não cadastrado",
      },
      department: {
        label: (
          <>
            <WorkIcon className={classes.icon_header} /> Departamento
          </>
        ),
        format: department => department || "Não cadastrado",
      },
      company: {
        label: (
          <>
            <RoomIcon className={classes.icon_header} /> Unidade de negócio
          </>
        ),
        format: company => company || "Não cadastrado",
      },
    },
  };

  const listHook = useListRepository({
    repository: usersRepository,
    defaultSort: "displayName",
    path: "users",
    query: [["anyPhone", 1]],
    forceRemove: true,
  });

  listHook.state.list = listHook.state.list.map(item => ({
    ...item,
    telephones: {
      principal: item.telephoneNumber,
      mobile: item.mobile,
      homePhone: item.homePhone,
      ipPhone: item.ipPhone,
    },
  }));

  const actions = getListActions(abilities, listHook, "users");
  return (
    <>
      <UserFilter />
      <List
        {...props}
        {...listHook}
        {...actions}
        hideDownloadIcon
        stickyHeader
        listOptions={listOptions}
      />
      <Snackbar
        autoHideDuration={4000}
        onClose={() => setMessage("")}
        message={message}
      />
    </>
  );
};

export default UsersList;
