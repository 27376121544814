import React from "react";
import { RouteByMenu } from "elentari";
import { makeStyles } from "@material-ui/core/styles";
import AccountBox from "@material-ui/icons/AccountBox";
import { Switch, Redirect, Route } from "react-router-dom";
import * as R from "ramda";
import { UsersList, useAbility, UserForm, UserView } from "./modules/users";
import { MicrosoftAuth } from "./modules/microsoftauth";
import logo from "./images/g10-logo-inverter.png";
import AppWrap from "./Components/AppWrap";

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "100%",
  },
  logoOpen: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: 128,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const allMenuItems = [
  {
    name: "users",
    label: "PESQUISA DE CONTATOS GRUPO G10 ",
    icon: AccountBox,
    pathname: "/users",
    list: UsersList,
    form: UserForm,
  },
];

export const Home = () => {
  const classes = useStyles();

  let menuItems = [];
  const abilities = useAbility();
  if (abilities) {
    menuItems = allMenuItems.filter(({ name }) => {
      return abilities.can("read", name);
    });
  }

  const defaultRedirect = R.pathOr("/", [0, "pathname"], menuItems);

  return (
    <AppWrap
      hideMenuButton
      logo={
        <div className={classes.logoClose}>
          <img alt="logo G10" src={logo} className={classes.logo} />
        </div>
      }
      userAvatarProps={{
        action: "Sair",
        label: "Olá,",
      }}
      menuItems={menuItems}
    >
      <Switch>
        <Route exact path="/">
          <Redirect to="/users" />
        </Route>
        <Route exact path="/loading" component={MicrosoftAuth} />
        <Route exact path="/users/:id/view" component={UserView} />
        <RouteByMenu menuItems={menuItems} />
        <Redirect to={defaultRedirect} />
      </Switch>
    </AppWrap>
  );
};
