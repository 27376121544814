import React, { useEffect } from "react";
import microsoftAuthRepository from "./microsoftAuthRepository";
import { useLocation } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import qs from "qs";

const MicrosoftAuth = props => {
  const location = useLocation();
  useEffect(() => {
    const code = qs.parse(location.search, { ignoreQueryPrefix: true }).code;
    if (code) {
      microsoftAuthRepository.create({ code: code }).then(response => {
        if (response.ok) {
          props.history.push("/users?ok=true");
        } else {
          props.history.push("/users?ok=false");
        }
      });
    }
  }, [props.history, location]);

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          <CircularProgress /> <br />
          Aguarde, seus contatos estão sendo sincronizados. Em breve eles
          estarão disponíveis na sua conta exchange.
        </Typography>
      </Grid>
    </Grid>
  );
};
export default MicrosoftAuth;
