import React from "react";
import { ReadView, useEntityManager } from "elentari";
import usersRepository from "./usersRepository";

const UserView = () => {
  const entityManager = useEntityManager({
    repository: usersRepository,
  });
  const config = [
    {
      children: {
        displayName: {
          label: "Nome",
          format: displayName => displayName || "Não cadastrado",
        },
        email: {
          label: "E-mail",
          format: email => email || "Não cadastrado",
        },
        telephoneNumber: {
          label: "Telefone principal",
          format: telephoneNumber => telephoneNumber || "Não cadastrado",
        },
        mobile: {
          label: "Celular",
          format: mobile => mobile || "Não cadastrado",
        },
        homePhone: {
          label: "Telefone fixo",
          format: homePhone => homePhone || "Não cadastrado",
        },
        ipPhone: {
          label: "Ramal",
          format: ipPhone => ipPhone || "Não cadastrado",
        },
        physicalDeliveryOfficeName: {
          label: "Empresa",
          format: physicalDeliveryOfficeName =>
            physicalDeliveryOfficeName || "Não cadastrado",
        },
        department: {
          label: "Departamento",
          format: department => department || "Não cadastrado",
        },
        company: {
          label: "Unidade de negócio",
          format: company => company || "Não cadastrado",
        },
        title: {
          label: "Cargo",
          format: title => title || "Não cadastrado",
        },
        city: {
          label: "Cidade",
          format: title => title || "Não cadastrado",
        },
        state: {
          label: "Estado",
          format: title => title || "Não cadastrado",
        },
      },
    },
  ];
  return <ReadView config={config} item={entityManager.item} edit={false} />;
};

export default UserView;
