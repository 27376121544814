import React from "react";
import { autocompleteHelpers } from "elentari";
import userFieldRepository from "./userFieldRepository";
import PureAutocomplete from "../../Components/PureAutocomplete";
const { toOption } = autocompleteHelpers;

const UserFieldAutocomplete = ({ field, ...props }) => {
  const loadField = async inputValue => {
    if (inputValue.length > 0) {
      const response = await userFieldRepository.list({
        query: {
          value: inputValue,
          field,
        },
        paginate: {
          limit: 10,
        },
      });
      if (response.ok) {
        return response.data.map(toOption(field));
      }
      return [];
    }
  };
  return (
    <PureAutocomplete
      {...props}
      repository={userFieldRepository}
      labelOption={field}
      loadOptions={loadField}
    />
  );
};

export default UserFieldAutocomplete;
